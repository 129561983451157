import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { ErrorState } from '@leagueplatform/web-common-components';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export function ErrorBoundaryWrapper({ children }: ErrorBoundaryProps) {
  return (
    <ObservabilityErrorBoundary fallback={<ErrorState />}>
      {children}
    </ObservabilityErrorBoundary>
  );
}
