/**
 * Check if the target element is a link.
 * @param target - The target element.
 * @returns true if the target is a link, false otherwise.
 */
export function isLink(target: HTMLElement): boolean {
  return target.tagName === 'A' && target.hasAttribute('href');
}

/**
 * Check if the link should be intercepted.
 * @param target - The target element.
 * @param pathToIntercept - The path to intercept.
 * @returns true if the link should be intercepted, false otherwise.
 */
export function shouldIntercept(
  target: HTMLElement,
  pathToIntercept: string,
): boolean {
  // const href = target.getAttribute('href');
  return target.getAttribute('href')?.includes(pathToIntercept) || false;
}

/**
 * Get the absolute root URL.
 * @param rootUrl - The root URL.
 * @returns The absolute root URL.
 */
export function getAbsoluteRootUrl(rootUrl: string): string {
  return rootUrl.startsWith('http://') || rootUrl.startsWith('https://')
    ? rootUrl
    : window.location.origin + rootUrl;
}

/**
 * Open a new tab with the fetched HTML content and submit the form if present.
 * @param htmlContent - The HTML content to write in the new tab.
 */
export function openNewTabWithForm(htmlContent: string): void {
  const newWindow = window.open('', '_blank');
  if (newWindow) {
    newWindow.document.open();
    newWindow.document.write(htmlContent);
    newWindow.document.close();
    newWindow.onload = () => {
      const form = newWindow.document.forms[0];
      if (form) {
        form.submit();
      }
    };
  }
}

export function setupNewWindow(htmlTitle = 'Loading...') {
  const newWindow = window.open('', '_blank');
  if (newWindow) {
    newWindow.document.open();
    newWindow.document.write(
      `<html><head><title>${htmlTitle}</title></head><body><div id="root"></div></body></html>`,
    );
    newWindow.document.close();
  }
  return newWindow;
}
