/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

// import { AuthViewLogin, AuthViewLogout } from 'pages/auth/Auth.pages';

import {
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  HealthJourneyRoutes,
  HealthNeedPages,
  HealthProfileRoutes,
  Home,
  OnboardingRoutesWithProvider,
  WalletRoutes,
  ClaimsRoutes,
} from 'components/LazyRoutes';

import ErrorPage from 'pages/Error404';
import { ABOUT_CIGNA_ROUTE, FORMS_CENTER_ROUTE } from 'common/constants';
import AboutCignaHealthcare from 'pages/about-cigna/about-cigna.page';
import { useConfigWithFeatureFlags } from 'hooks/use-config-with-feature-flags';
import FormsCenterLandingPage from 'pages/forms-center/forms-center-landing-page.page';
import { useInterceptLinkClick } from 'hooks/use-intercept-link-click.hook';
import { AuthViewLogin } from 'pages/auth/Auth.pages';
import { AuthSessionContainer } from './auth-session-container/auth-session-container';
import { OnboardingWrapper } from './onboarding/onboarding-wrapper';
import { BasePageProvider } from './base-page-provider/base-page-provider.component';
import { AuthSignIn } from './auth/auth-sign-in.component';
import { AuthSignOut } from './auth/auth-sign-out.component';

function AppRoutes() {
  useConfigWithFeatureFlags();
  useInterceptLinkClick('/sso/vendors/', import.meta.env.VITE_LINKOUT_ROOT_URL);

  return (
    <Switch>
      {/* <Route path="/start-login" component={AuthSignIn} /> */}
      <Route path="/sign-in" component={AuthSignIn} />
      <Route path="/sign-out" component={AuthSignOut} />
      <Route path="/" exact component={LandingPage} />
      <AuthSessionContainer>
        <OnboardingWrapper>
          <BasePageProvider>
            <Switch>
              <Route path="/home" component={Home} exact key="home" />
              <Route
                path={[
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                  getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
                ]}
                component={HealthJourneyRoutes}
                key="health-journey"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
                component={CareCollectionsRoutes}
                key="care-collections"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                component={CareDiscoveryRoutes}
                key="care-discovery"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
                component={HealthNeedPages}
                key="health-needs"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
                component={WalletRoutes}
                key="wallet"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
                component={HealthProfileRoutes}
                key="health-profile"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.onboarding)}
                component={OnboardingRoutesWithProvider}
                key="onboarding"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
                component={ClaimsRoutes}
                key="claims"
              />
              <Route
                path={ABOUT_CIGNA_ROUTE}
                component={AboutCignaHealthcare}
                key="about"
              />
              <Route
                path={FORMS_CENTER_ROUTE}
                component={FormsCenterLandingPage}
                key="forms-center"
              />
              <Route component={ErrorPage} />
            </Switch>
          </BasePageProvider>
        </OnboardingWrapper>
      </AuthSessionContainer>
    </Switch>
  );
}

export default AppRoutes;
