import { useCallback, useEffect, useState } from 'react';

import { useLocalStorage } from 'hooks/use-local-storage';
import { OnboardingFeatureHighlights } from 'components/onboarding/feature-highlights.component';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { AllSetPage } from './all-set.component';

export const OnboardingWrapper = ({ children }: { children: JSX.Element }) => {
  const [featureHighlightCompleted, setFeatureHighlightCompleted] =
    useLocalStorage('featureHighlightCompleted', false);
  const [showFeatureHighlights, setShowFeatureHighlights] = useState(false);
  const [showAllSetPage, setShowAllSetPage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFeatureHighlights(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleFeatureHighlightCompleted = useCallback(() => {
    setShowAllSetPage(true);
  }, []);

  const handleOnboardingCompleted = useCallback(() => {
    setFeatureHighlightCompleted(true);
  }, [setFeatureHighlightCompleted]);

  if (!featureHighlightCompleted && !showFeatureHighlights) {
    return <FullPageLoadingSpinner />;
  }

  if (!featureHighlightCompleted && showFeatureHighlights && !showAllSetPage) {
    return (
      <OnboardingFeatureHighlights
        onOnboardingComplete={handleFeatureHighlightCompleted}
      />
    );
  }

  if (!featureHighlightCompleted && showAllSetPage) {
    return <AllSetPage handleOnboardingCompleted={handleOnboardingCompleted} />;
  }

  return children;
};
