import {
  SocketAsFetch as LeagueSocketAsFetch,
  WebsocketMessage,
  FetchOptions,
} from '@leagueplatform/socket-as-fetch';
import { combineErrorContexts } from '@leagueplatform/observability';

export const socketAsFetch = (
  message: WebsocketMessage,
  options?: FetchOptions,
) =>
  LeagueSocketAsFetch.fetch(message, {
    errorContext: combineErrorContexts([
      {
        errorName: `Cigna Socket Fetch Response Error - ${message.message_type}`,
      },
      options?.errorContext || {},
    ]),
  });
