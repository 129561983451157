import { getMasonryWSDriver } from '@leagueplatform/masonry';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { MasonryEngine } from '@leagueplatform/masonry-engine';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { ParagraphText, StackLayout } from '@leagueplatform/genesis-core';

const MasonryWSDriver = getMasonryWSDriver({
  appId: 'cigna_form_center',
  screenName: 'form-center',
  productArea: 'cigna_form_center',
  apiParams: {},
});

const FormsCenterLandingPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'FORMS_CENTER' }));

  return (
    <MainPageContainer width="100%">
      <HeroSection title={formatMessage({ id: 'FORMS_CENTER' })} />
      <StackLayout
        css={{
          maxWidth: 1048,
        }}
      >
        <ParagraphText emphasis="subtle">
          {formatMessage({ id: 'FORMS_CENTER_SUBTITLE' })}
        </ParagraphText>
        <StackLayout
          css={{
            paddingTop: '$three',
            paddingBottom: '$five',
            width: '100%',
          }}
        >
          <MasonryEngine driver={MasonryWSDriver} />
        </StackLayout>
      </StackLayout>
    </MainPageContainer>
  );
};

export default FormsCenterLandingPage;
