export const EN_STRING_OVERRIDES = {
  ADVANCE_TO_NEXT: 'Advance to next',
  AND: 'and',
  FAQ: 'FAQs',
  MARK_AS_DONE: 'Mark As Completed',
  ACCOUNT_SETTINGS: 'Account',
  SUPPORT: 'Get Support',
  FORMS_CENTER: 'Forms Center',
  FORMS_CENTER_SUBTITLE:
    'Complete a claim form only if your provider isn’t filing one for you.',
  LOG_OUT: 'Sign Out',
  PERSONAL_INFO: 'Manage Profile Settings',
  MANAGE_PHARMACY_SETTINGS: 'Manage Pharmacy Settings',
  PRIVACY_POLICY: 'Privacy Policy',
  PRIVACY_NOTICE: 'Privacy Notice',
  PRIVACY_FORMS: 'Privacy Forms',
  HIPPA_NOTICE_OF_PRIVACY_PRACTICES: 'HIPPA Notice of Privacy Practices',
  GRAMM_LEACH_BILLEY_ACT_NOTICE: 'Gramm-Leach-Billey Act Notice',
  TERMS_OF_SERVICE: 'Terms of Service',
  NONDISCRIMINATION_NOTICE: 'Nondiscrimination Notice',
  LANGUAGE_ASSISTANCE: 'Language Assistance',
  APPEALS: 'Appeals',
  GRIEVANCES: 'Grievances',
  DISCLAIMERS: 'Disclaimers',
  DISCLAIMER_COPY: 'Disclaimer copy',
  COPYRIGHT_TEXT: 'Copyright © 2024 myCignaMedicare',
  RATE_US_IN_THE_APP_STORE: 'Rate us in the App Store',
  CLOSE: 'Close',
  ACTIVITIES: 'Updates',
  MESSAGES: 'Messages',
  CLAIMS: 'Claims',
  WALLET: 'Benefits',
  ABOUT_CIGNA_HEALTHCARE: 'About myCignaMedicare',
  ABOUT_CIGNA_HEALTHCARE_BODY_1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  ARE_YOU_SURE: 'Are you sure?',
  ACCOUNT_DELETED_TITLE: 'Your account is now deleted',
  ACCOUNT_DELETED: 'Account deleted',
  ACCOUNT_DELETED_BODY_1:
    'Please note that we are required by law to retain certain information associated with your account.',
  ACCOUNT_DELETED_BODY_2:
    'As soon as we are legally able to delete this information, it will be deleted in accordance with our data deletion and retention policy. Until then, it is protected and stored securely and is not used in any way.',
  CONTINUE: 'Continue',
  CONTINUE_WITH_MYCIGNA: 'Continue with myCignaMedicare',
  SAVE: 'Save',
  ERROR_CAPTION_BACKEND:
    'There was a problem loading data.\nPlease try again later.',
  ERROR_CAPTION_NO_CONNECTION:
    'Please check your internet.\nThis page will automatically reload when you reconnect.',
  DELETE_ACCOUNT: 'Delete account',
  YES_DELETE_ACCOUNT: 'Yes, delete this account',
  NEVER_MIND: 'Never mind',
  I_UNDERSTAND: 'I understand',
  DELETE_YOUR_ACCOUNT: 'Delete Your Account',
  DELETE_ACCOUNT_TITLE: 'Delete My Account',
  DELETE_ACCOUNT_BODY_1:
    "By deleting your account, you'll lose access to the myCignaMedicare app. You will still have access to your health plan. If you would like to delete your myCigna username and password then please call 1-800-223-9730.",
  DELETE_ACCOUNT_BODY_2:
    'If you access the app again and login with your Cigna credentials a new account will be created on the myCignaMedicare.',
  CLAIMS_FILTER_VALUE_APPROVED: 'Processed',
  CLAIMS_FILTER_VALUE_PENDING: 'Processing',
  CLAIMS_FILTER_VALUE_ADJUSTED: 'Adjusted',
  CLAIMS_STATUS_DEFINITION_APPROVED: 'Processed means the claim is approved.',
  PRIVACY_AND_LEGAL_DOCUMENTS: 'Privacy and Legal Documents',
  WALLET_WITH_CLAIMS_LANDING_TITLE:
    'Your benefits, claims, and wellness info, all in one place.',
  WALLET_LANDING_TITLE: 'Your benefits and wellness info, all in one place. ',
  YOU_ARE_ALL_SET_TITLE: 'You’re all set',
  YOU_ARE_ALL_SET_DESC:
    'If you’d like to personalize things even more, complete your health assessment. It’s not mandatory, but it does help us tailor the experience just for you.',
  NOT_NOW: 'Not now',
  FOR_PROVIDERS_AND_PHARMACISTS: 'Policy Information',
  OPEN_MENU: 'Open Menu',
  MENU: 'Menu',
};
