import type { GDSIconProps } from '@leagueplatform/genesis-core';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import claimsIcon from 'assets/claims-icon.png';

enum NavIds {
  HOME = 'HOME',
  BENEFITS = 'BENEFITS',
  CARE = 'GET_CARE',
  CLAIMS = 'CLAIMS',
  MESSAGES = 'MESSAGES',
}

type NavItem = {
  to:
    | string
    | {
        search: string;
        pathname: string;
        state: unknown;
        hash: string;
        key?: string | undefined;
      };
};

export interface NavLink extends NavItem {
  message_id: NavIds;
  icon?: GDSIconProps['icon'];
}

export const useNavLinksList = () => {
  const NavLinksList: NavLink[] = [
    {
      to: '/home',
      message_id: NavIds.HOME,
      icon: 'illustrativeHome',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.wallet),
      message_id: NavIds.BENEFITS,
      icon: 'capabilityWallet',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
      message_id: NavIds.CARE,
      icon: 'illustrativeHeart',
    },
    {
      to: '/claims?h=1',
      message_id: NavIds.CLAIMS,
      icon: claimsIcon,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
      message_id: NavIds.MESSAGES,
      icon: 'capabilityJourney',
    },
  ];

  return {
    navLinks: NavLinksList,
  };
};
