import { captureAppError } from '@leagueplatform/observability';
import { getAppInitDetails } from 'api/get-app-init-details';
import { SENTRY_CONSTANTS } from 'common/constants';

export const getUserEligibility = async (isAuthenticated: boolean) => {
  try {
    const appInitDetails = await getAppInitDetails();

    return {
      eligible: appInitDetails.eligibilityInfo.eligible,
      details: appInitDetails.eligibilityInfo,
    };
  } catch (e: any) {
    captureAppError(new Error('Failed to fetch user eligibility'), {
      context: {
        Context: {
          authenticated: isAuthenticated,
          message: e?.message,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      tags: {
        authenticated: isAuthenticated,
      },
    });
    return { eligible: false, details: null, error: true };
  }
};
