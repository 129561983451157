import { GDSIconProps, GDSStyleObject } from '@leagueplatform/genesis-core';

export interface FooterLinkProps {
  text: string;
  url?: string;
  linkProps?: GDSStyleObject;
  icon?: GDSIconProps['icon'];
  target?: string;
}

const boldTextLink: GDSStyleObject = {
  fontWeight: 'bold',
};

export const footerLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'LANGUAGE_ASSISTANCE',
    url: 'https://www.cigna.com/static/www-cigna-com/docs/multi-language-interpreter-services.pdf',
    target: '_blank',
    linkProps: boldTextLink,
    icon: 'interfaceExternalLink',
  },
  {
    text: 'TERMS_OF_SERVICE',
    url: 'https://www.cigna.com/legal/compliance/',
    target: '_blank',
    linkProps: boldTextLink,
    icon: 'interfaceExternalLink',
  },
];
export const footerSubLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'PRIVACY_POLICY',
    url: 'https://www.cigna.com/medicare/privacy',
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'PRIVACY_NOTICE',
    url: '',
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'PRIVACY_FORMS',
    url: 'https://www.cigna.com/medicare/member-resources/customer-forms',
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'HIPPA_NOTICE_OF_PRIVACY_PRACTICES',
    url: 'https://www.cigna.com/static/www-cigna-com/docs/cigna-health-care-and-cigna-supplemental-benefits-privacy-notice-eng.pdf',
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'GRAMM_LEACH_BILLEY_ACT_NOTICE',
    url: 'https://www.cigna.com/static/www-cigna-com/docs/gramm-leach-bliley-act-privacy-notice.pdf',
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'NONDISCRIMINATION_NOTICE',
    url: 'https://www.cigna.com/medicare/nondiscrimination',
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'APPEALS',
    url: '',
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'GRIEVANCES',
    url: '',
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'DISCLAIMERS',
    url: '',
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
];
