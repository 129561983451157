/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  Card,
  Box,
  StackLayout,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';

import { useIntl } from '@leagueplatform/locales';

interface AboutBodySectionProps {
  image: string;
  imageAltId: string;
  htmlString: string;
}

export const AboutBodySection: React.FC<AboutBodySectionProps> = ({
  image,
  imageAltId,
  htmlString,
}) => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      orientation={{
        '@initial': 'horizontal',
        '@mobile': 'vertical',
      }}
      verticalAlignment="top"
      horizontalAlignment="center"
      css={{
        padding: '0 $threeQuarters $threeQuarters',
        '@mobile': { paddingBlock: '$one' },
      }}
    >
      <Box
        css={{
          padding: '16px 40px 16px 0',
        }}
      >
        <Card.Image
          image={image}
          css={{ width: '100px', height: '54px' }}
          imageAlt={formatMessage({ id: imageAltId })}
        />
      </Box>
      <Box
        css={{
          '.GDS-heading-text, table': {
            marginBlockEnd: '$half',
          },
          table: {
            width: 'fit-content',
            '@mobile': {
              'a[href^="mailto"]': {
                wordBreak: 'break-word',
              },
            },
          },
        }}
      >
        <HtmlToReact
          htmlString={htmlString}
          options={{
            h3: {
              component: HeadingText as React.FunctionComponent,
              props: { level: '2', size: 'lg' },
            },
            h6: { component: HeadingText, props: { level: '6', size: 'xs' } },
            p: {
              component: ParagraphText as React.FunctionComponent,
              props: {
                'margin-bottom': '24px',
              },
            },
            ul: {
              component: ParagraphText as React.FunctionComponent,
              props: {
                as: 'ul',
                css: {
                  listStyle: 'revert',
                  paddingLeft: '$oneAndHalf',
                  li: { typography: '$bodyOne' },
                },
              },
            },
          }}
        />
      </Box>
    </StackLayout>
  );
};
