// Auto-generated file: Do not modify directly. Changes may be overwritten. Version: 1.1.0.

/*
 * Following tokens have been deprecated in version 1.1.0, please update your theme:
 *
 * Interactive/Focus has been split into two separate tokens. Please define 'Interactive/Focus Inner' and 'Interactive/Focus Outer' instead.
 * 'Interactive/Background Default' is deprecated. Please use 'Input/Background Default' instead.
 * 'Interactive/Background Disabled' is deprecated. Please use 'Input/Background Disabled' instead.
 * 'Interactive/Background Hovered' is deprecated. Please use 'Input/Background Hovered' instead.
 * 'Interactive/Background Pressed' is deprecated. Please use 'Input/Background Pressed' instead.
 * 'Interactive/Border Default' is deprecated. Please use 'Input/Border Default' instead.
 * 'Interactive/Border Hovered' is deprecated. Please use 'Input/Border Hovered' instead.
 * 'Interactive/Border Disabled' is deprecated. Please use 'Input/Border Disabled' instead.
 * 'Interactive/Border Critical Default' is deprecated. Please use 'Input/Border Critical' instead.
 * 'Pill' is deprecated. Please use 'Circle' instead.
 */

import type { GDSTheme } from '@leagueplatform/genesis-core';

export const cignaHealthTheme: GDSTheme = {
  colors: {
    surface: {
      background: {
        primary: '#FFFFFFFF',
        secondary: '#F4F4F4FF',
        // tertiary: '#A1F7A1FF',
        tertiary: '#F4F4F4FF',
      },
      card: {
        primary: '#F4F4F4FF',
        secondary: '#FFFFFFFF',
        disabled: '#D7D7D7FF',
      },
    },
    onSurface: {
      text: {
        primary: '#000000FF',
        subdued: '#333333FF',
        reversed: '#FFFFFFFF',
        critical: '#BA0000FF',
        success: '#488319FF',
        warning: '#333333FF',
      },
      border: { subdued: '#E0E3E6FF', default: '#959595FF' },
      icon: { primary: '#333333FF' },
    },
    interactive: {
      action: {
        primary: '#0033FFFF',
        hovered: '#1900BFFF',
        pressed: '#0033FFFF',
        subdued: '#333333FF',
        disabled: '#D6D6D6FF',
      },
      icon: { default: '#0033FFFF', disabled: '#949494FF' },
      background: {
        default: '#FFFFFFFF',
        disabled: '#F4F4F4FF',
        hovered: '#F8F8F8FF',
        pressed: '#F4F4F4FF',
      },
      border: {
        default: '#949494FF',
        hovered: '#0033FFFF',
        disabled: '#D6D6D6FF',
        critical: { default: '#A12127FF' },
      },
      focus: { inner: '#1900BFFF', outer: '#1900BFFF' },
    },
    critical: {
      background: { subdued: '#F9E1E2FF', secondary: '#FFFFFFFF' },
      border: { default: '#BA0000FF' },
      icon: '#BA0000FF',
    },
    warning: {
      background: { subdued: '#FAD6BDFF', secondary: '#FFFFFFFF' },
      border: { default: '#FAA163FF' },
      icon: '#FAA163FF',
    },
    success: {
      background: { subdued: '#A1F7A1FF', secondary: '#FFFFFFFF' },
      border: { default: '#03CC54FF' },
      icon: '#488319FF',
    },
    highlight: {
      background: { subdued: '#5F9FFFFF', secondary: '#FFFFFFFF' },
      border: { default: '#1900BFFF' },
      icon: '#1900BFFF',
    },
    decorative: {
      brand: {
        primary: {
          darkest: '#074664FF',
          dark: '#110081FF',
          default: '#0033FFFF',
          bright: '#5F9FFFFF',
          brightest: '#BBE9FFFF',
          pastel: '#F4F4F4FF',
        },
        secondary: {
          darkest: '#074E7AFF',
          dark: '#1C7EBBFF',
          default: '#00874DFF',
          bright: '#03CC54FF',
          brightest: '#FAD6BD',
          pastel: '#F1FAFFFF',
        },
        tertiary: {
          darkest: '#845A09FF',
          dark: '#C2891BFF',
          default: '#FF4D00FF',
          bright: '#FAA163FF',
          brightest: '#FAD6BDFF',
          pastel: '#FFF8EAFF',
        },
      },
    },
    primary: {
      background: {
        default: '#0033FFFF',
        hovered: '#208BBDFF',
        pressed: '#005075FF',
        critical: {
          default: '#BA0000FF',
          hovered: '#BA0000FF',
          pressed: '#BA0000FF',
        },
      },
      text: { default: '#FFFFFFFF', critical: { default: '#FFFFFFFF' } },
    },
    secondary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#208BBDFF',
        pressed: '#F0F0F0FF',
      },
      text: { default: '#0033FFFF', hovered: '#FFFFFFFF' },
      border: { default: '#006594FF' },
    },
    tertiary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#208BBDFF',
        pressed: '#F0F0F0FF',
      },
      text: { default: '#006594FF', hovered: '#FFFFFFFF' },
      border: { default: '#707E89FF' },
    },
    input: {
      border: {
        success: '#159369FF',
        warning: '#FF4D00FF',
        default: '#949494FF',
        hovered: '#0033FFFF',
        disabled: '#D6D6D6FF',
        critical: '#A12127FF',
      },
      background: {
        default: '#FFFFFFFF',
        disabled: '#F4F4F4FF',
        hovered: '#F8F8F8FF',
        pressed: '#F4F4F4FF',
      },
    },
  },
  shadows: {
    card: { x: 0, y: 2, blur: 6, spread: 0, color: '#00000014' },
    dropdown: { x: 0, y: 4, blur: 8, spread: 0, color: '#0000001A' },
  },
  typography: {
    headingOne: {
      fontFamily: 'Merriweather',
      fontWeight: 700,
      lineHeight: 40,
      fontSize: 32,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingTwo: {
      fontFamily: 'Merriweather',
      fontWeight: 700,
      lineHeight: 32,
      fontSize: 24,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingThree: {
      fontFamily: 'Merriweather',
      fontWeight: 700,
      lineHeight: 28,
      fontSize: 20,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingFour: {
      fontFamily: 'Merriweather',
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 18,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleOne: {
      fontFamily: 'Urbanist',
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleTwo: {
      fontFamily: 'Urbanist',
      fontWeight: 700,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOne: {
      fontFamily: 'Urbanist',
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOneSecondary: {
      fontFamily: 'Urbanist',
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwo: {
      fontFamily: 'Urbanist',
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwoSecondary: {
      fontFamily: 'Urbanist',
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonOne: {
      fontFamily: 'Urbanist',
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonTwo: {
      fontFamily: 'Urbanist',
      fontWeight: 600,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    label: {
      fontFamily: 'Urbanist',
      fontWeight: 500,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    overline: {
      fontFamily: 'Urbanist',
      fontWeight: 700,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'UPPER',
      textDecoration: 'none',
    },
    caption: {
      fontFamily: 'Urbanist',
      fontWeight: 400,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    tab: {
      fontFamily: 'Urbanist',
      fontWeight: 400,
      lineHeight: 12,
      fontSize: 10,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
  },
  spacing: {
    none: 0,
    quarter: 4,
    half: 8,
    threeQuarters: 12,
    one: 16,
    oneAndHalf: 24,
    two: 32,
    twoAndHalf: 40,
    three: 48,
    four: 64,
    five: 80,
  },
  borderWidths: { thin: 1, thick: 2, outerFocus: 3, innerFocus: 3 },
  borderRadii: {
    none: 0,
    thin: 1,
    thick: 2,
    small: 3,
    medium: 6,
    large: 9,
    extraLarge: 12,
    huge: 20,
    circle: 99999,
    pill: 99999,
    button: 99999,
    inputField: 6,
  },
  zIndices: {
    default: 1,
    sticky: 100,
    dropdown: 4000,
    tooltip: 5000,
    modal: 8000,
    toast: 9000,
  },
  rem: {
    fontSizes: {
      headingOne: 2,
      headingTwo: 1.5,
      headingThree: 1.25,
      headingFour: 1.125,
      subtitleOne: 1,
      subtitleTwo: 0.875,
      bodyOne: 1,
      bodyOneSecondary: 1,
      bodyTwo: 0.875,
      bodyTwoSecondary: 0.875,
      buttonOne: 1,
      buttonTwo: 0.875,
      label: 0.75,
      overline: 0.75,
      caption: 0.75,
      tab: 0.625,
    },
    lineHeights: {
      headingOne: 2.5,
      headingTwo: 2,
      headingThree: 1.75,
      headingFour: 1.5,
      subtitleOne: 1.5,
      subtitleTwo: 1.25,
      bodyOne: 1.5,
      bodyOneSecondary: 1.5,
      bodyTwo: 1.25,
      bodyTwoSecondary: 1.25,
      buttonOne: 1.5,
      buttonTwo: 1.25,
      label: 1,
      overline: 1,
      caption: 1,
      tab: 0.75,
    },
    baseSize: 16,
  },
  fonts: {
    headings: 'Merriweather',
    body: 'Urbanist',
  },
  transitions: { defaultTime: '200ms' },
};
