import { Box, StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { LinkComponent } from 'components/form-center/custom-link.component';
import {
  FooterLinkProps,
  footerLinksConfig,
  footerSubLinksConfig,
} from './footer-link-list.hook';

export const FooterComponent = () => {
  const linkItems = footerLinksConfig();
  const subLinkItems = footerSubLinksConfig();
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      css={{
        background: '$surfaceBackgroundSecondary',
        flexWrap: 'wrap',
        paddingTop: '$three',
        paddingBottom: '$twoAndHalf',
        '@laptop': {
          flexWrap: 'nowrap',
        },
        '@desktop': {
          flexWrap: 'nowrap',
        },
      }}
    >
      <MainPageContainer width="100%" padding="0">
        {/* Footer Links Section */}
        <Box css={{ '@mobile': { display: 'none' } }}>
          {linkItems?.map((item: FooterLinkProps) => (
            <LinkComponent key={item.text} {...item} />
          ))}
        </Box>
        <Box
          css={{
            margin: '$one 0 $oneAndHalf 0',
            '@mobile': { display: 'none' },
          }}
        >
          {subLinkItems?.map((item: FooterLinkProps) => (
            <LinkComponent key={item.text} {...item} />
          ))}
        </Box>
        <UtilityText size="xs" css={{ marginLeft: '$half' }}>
          {formatMessage({ id: 'COPYRIGHT_TEXT' })}
        </UtilityText>
      </MainPageContainer>
    </StackLayout>
  );
};
