import { Box } from '@leagueplatform/genesis-core';
import React from 'react';

export const FullScreenContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    role="dialog"
    css={{
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: '100',
      overflow: 'auto',
      '[aria-describedby="onboarding-fh-title"]': {
        color: '#66686B',
        fontSize: '14px',
        '&:hover': {
          background: 'none',
        },
      },
      '[aria-current="true"] > span > span > div': {
        backgroundColor: '#0033FF',
        borderColor: '#0033FF',
      },
      '[aria-live="polite"] p': {
        fontWeight: '700',
        letterSpacing: '-0.2px',
      },
    }}
  >
    {children}
  </Box>
);
