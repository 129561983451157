import { initializeApp } from 'firebase/app';
import type { RemoteConfig } from 'firebase/remote-config';
import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: 'AIzaSyAbNcfQqWKrYDsui99bw5HRYU3DRG7iJC0',
  authDomain: 'league-cigna-firebase.firebaseapp.com',
  projectId: 'league-cigna-firebase',
  storageBucket: 'league-cigna-firebase.appspot.com',
  messagingSenderId: '452818196578',
  appId: '1:452818196578:web:e6bd4bf1c3b4d12257993b',
  measurementId: 'G-3LNVCE5P8T',
};

const firebaseApp = initializeApp(firebaseConfig);

// Firebase Remote Config
enum FEATURE_FLAGS {
  HEALTH_JOURNEY_TABS = 'bus_mht_availableJourneyExperienceTabs',
}

const REMOTE_CONFIG_DEFAULT_VALUES: Record<FEATURE_FLAGS, string> = {
  [FEATURE_FLAGS.HEALTH_JOURNEY_TABS]: JSON.stringify({
    tabs: ['activities', 'history'],
  }),
};

const remoteConfig: RemoteConfig = getRemoteConfig(firebaseApp);

remoteConfig.defaultConfig = REMOTE_CONFIG_DEFAULT_VALUES;

// Override the default 12 hours to 1 hour for production (to align with mobile) and 0 for staging (for easier testing).
// We want to use Remote Config feature flags more like we use BE - immediate result after flipping the flag, which is currently impossible.
// Real-time Remote config not available for web (yet?): https://firebase.google.com/docs/remote-config/real-time
remoteConfig.settings.minimumFetchIntervalMillis =
  import.meta.env.VITE_ENV === 'staging' ? 0 : 3600 * 1000; // 1 hour

export { remoteConfig, REMOTE_CONFIG_DEFAULT_VALUES, FEATURE_FLAGS };
