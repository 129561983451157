import { OnboardingRoutesWithProvider } from '@leagueplatform/onboarding';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';

export const OnboardingFeatureHighlights = ({
  onOnboardingComplete,
}: {
  onOnboardingComplete: (error?: Error) => void;
}) => (
  <FullScreenContainer>
    <OnboardingRoutesWithProvider
      contentfulId={import.meta.env.VITE_CONTENTFUL_ID}
      tenantId={import.meta.env.VITE_TENANT_ID}
      onOnboardingComplete={onOnboardingComplete}
    />
  </FullScreenContainer>
);
