import React, { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { LoadingSpinner } from 'components/loaders/loading-spinner.component';

export const AuthSignOut = () => {
  useEffect(() => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
    });
  }, []);

  return <LoadingSpinner />;
};
