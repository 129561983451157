import { Auth } from '@leagueplatform/auth';

/**
 * Fetch data with an authorization token.
 * @param url - The URL to fetch data from.
 * @returns The fetched data.
 */
export async function fetchDataWithToken(url: string): Promise<string> {
  const token = await Auth.getToken();
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text/html',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.text();
}
