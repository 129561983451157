import * as React from 'react';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { Box, GDSStyleObject, globalCss } from '@leagueplatform/genesis-core';
import { APP_NAV_DEFAULT_HEIGHT } from './header-nav/navbar/nav.constants';
import { AppNavBarComponent } from './header-nav/navbar/nav.component';
import { FooterComponent } from './footer/footer.component';

interface Props {
  isPrivatePage?: boolean;
  children: React.ReactNode;
  css?: GDSStyleObject;
}

const globalStyles = globalCss({
  label: {
    '&:focus': {
      outline: 'none !important',
      boxShadow: 'none !important',
    },
    '&:focus-within': {
      outline: 'none !important',
      boxShadow: 'none !important',
    },
    '& > span > input[type="checkbox"]': {
      backgroundColor: 'transparent',
      '&:checked': {
        backgroundColor: 'transparent',
      },
    },
  },
  '#home-page .masonry-page-header-background': {
    height: '50px !important',
    '@media screen and (max-width: 1024px)': {
      height: '20px !important',
    },
  },
  a: {
    maxWidth: 'none !important',
  },
  'img[alt="phone with four finger holding it"]': {
    objectFit: 'contain',
  },
  // the card item from masonry is not using the theme's card surface card style so i had to explore this approach to identify a card
  '.GDS-stack-item > div > div.GDS-vertical-stack-layout > div.GDS-vertical-stack-layout':
    {
      backgroundColor: '#F4F4F4',
    },
  '.GDS-elevated-card': {
    boxShadow: '2px 2px 7px 0px #00000017 !important',
    '& .GDS-card-click-area': {
      boxShadow: 'none !important',
      border: 'none !important',
    },
  },
});

export const BasePage = ({
  children,
  isPrivatePage = true,
  css,
  ...boxProps
}: Props) => {
  useScrollTopOnLocationChange();

  globalStyles();
  return (
    <>
      {isPrivatePage && <AppNavBarComponent />}
      <Box
        as="main"
        css={{
          overflowX: 'hidden',
          position: 'relative',
          flex: '1',
          minHeight: `calc(100vh - ${APP_NAV_DEFAULT_HEIGHT}px)`,
          ...css,
        }}
        {...boxProps} // eslint-disable-line
      >
        {children}
      </Box>
      {isPrivatePage && <FooterComponent />}
    </>
  );
};
