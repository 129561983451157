import { useState, useEffect } from 'react';

import { EligibilityInfo } from 'api/get-app-init-details';
import { getUserEligibility } from 'api/get-user-eligibility';

interface EligibilityData {
  loading: boolean | null;
  eligible?: boolean | null;
  details?: EligibilityInfo | null;
  error?: boolean | null;
}

export const useUserEligibility = (isAuthenticated: boolean | undefined) => {
  const [eligibilityData, setEligibilityData] = useState<EligibilityData>({
    eligible: null,
    details: null,
    loading: null,
    error: null,
  });

  useEffect(() => {
    const fetchEligibility = async () => {
      if (!isAuthenticated) {
        return;
      }

      try {
        setEligibilityData({
          loading: true,
        });
        const data = await getUserEligibility(isAuthenticated);
        setEligibilityData({
          eligible: data.eligible,
          details: data.details,
          loading: false,
          error: false,
        });
      } catch (error) {
        setEligibilityData({
          eligible: false,
          details: null,
          loading: false,
          error: true,
        });
      }
    };

    fetchEligibility();
  }, [isAuthenticated]);

  return eligibilityData;
};
