import {
  StackLayout,
  Button,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { isExternalLink } from '@cigna-web/shared-module';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import { ErrorTypes, useErrorMessage } from 'hooks/use-error-message.hook';
import errorIcon from 'assets/error.svg';

export const FullPageError = ({
  errorType,
  buttonOnClick,
  buttonLink,
  buttonChildren,
  customMessage,
}: {
  errorType: ErrorTypes;
  buttonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonLink?: string;
  buttonChildren?: React.ReactNode;
  customMessage?: string;
}) => {
  const { errorHeading, errorCaption } = useErrorMessage(
    errorType,
    customMessage,
  );

  return (
    <FullScreenContainer>
      <StackLayout
        orientation="vertical"
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{
          height: '100%',
          whiteSpace: 'pre-wrap',
        }}
      >
        <Image
          src={errorIcon}
          alt="error icon"
          width="147px"
          height="auto"
          position="relative"
          top="minusOne"
          marginBottom="three"
        />
        <HeadingText
          level="1"
          size="xl"
          css={{
            marginBottom: '$half',
          }}
        >
          {errorHeading}
        </HeadingText>
        <ParagraphText
          emphasis="subtle"
          css={{ textAlign: 'center', maxWidth: '351px' }}
        >
          {errorCaption}
        </ParagraphText>
        {buttonLink ? (
          <Button
            as="a"
            href={buttonLink}
            target={isExternalLink(buttonLink) ? '_blank' : '_self'}
            css={{ marginTop: '$two', minWidth: '180px', height: '48px' }}
          >
            {buttonChildren}
          </Button>
        ) : (
          buttonOnClick &&
          buttonChildren && (
            <Button
              css={{ marginTop: '$two', minWidth: '180px', height: '48px' }}
              onClick={buttonOnClick}
            >
              {buttonChildren}
            </Button>
          )
        )}
      </StackLayout>
    </FullScreenContainer>
  );
};
